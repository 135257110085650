
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "dashboard",
  components: {},
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    getNotificationIcon(notification) {
      switch (notification.type) {
        case "App\\Notifications\\DonorDisabled":
          return "Communication/Delete-user";
        default:
          return "";
      }
    },
    acknowledge(id) {
      ApiService.put("notifications", { notificationId: id }).then(
        (response) => {
          this.retrieveData();
        }
      );
    },
    retrieveData() {
      ApiService.query("notifications", {}).then((response) => {
        this.notifications = response.data.data;
      });
    },
  },
  mounted() {
    this.retrieveData();
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Tablero");
    });
  },
});
